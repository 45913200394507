import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { matchRoutes } from 'react-router-config'
import classNames from 'classnames'
import { Layout } from 'antd'
import { initTheme } from '_src/themes'
import { useAppSelector } from '_src/store/hooks'
import routes from '_src/routes'
import { flattenArray, findArrayParents } from '../../utils'
import './index.less'
import PassHeader from '../../components/PassHeader'
import PassFooter from '../../components/PassFooter'
import { useAppDispatch } from '../../store/hooks'
import { setActiveUrl } from '_src/store/menu/reducer'
const { Content } = Layout

export default function PageLayout({ children, className }) {
  const { theme } = useAppSelector((store) => store.global)
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const routeArray = flattenArray(routes())
  const [show, setShow] = useState(false)
  // 导航操作 start
  const handleSetActiveUrl = (pathname) => {
    // 设置导航焦点
    const marchRoutes = matchRoutes(routeArray, pathname)
    const path = marchRoutes[0]?.match?.path || ''

    if (path) {
      const urls = findArrayParents(routes(), (data) => data.path === path)
      urls && dispatch(setActiveUrl(urls))
    }
  }
  useEffect(() => {
    history.listen((historyLocation) => {
      // 每次路由变化都会执行这个方法
      const historyItem = routeArray.find((item) => item.path === historyLocation.pathname)
      if (historyItem) {
        const { hideFooter = false } = historyItem
        setShow(hideFooter)
      }

      handleSetActiveUrl(historyLocation.pathname)

      window && window.scrollTo(0, 0)
    })
  }, [history])
  useEffect(() => {
    //  设置导航焦点
    handleSetActiveUrl(pathname)
    // 钱包
    initTheme(theme || 'dark')
  }, [])
  return (
    <Layout className={classNames('aacPass-page-layout', className)} id="backTop">
      <PassHeader />
      <Content className={classNames('cust-content')}>{children}</Content>
      {!show && <PassFooter />}
    </Layout>
  )
}
