import { createSlice } from '@reduxjs/toolkit'
// import { isAppSupportedNetworkId } from '_src/utils/chain'
// state数据
let initialState = {
  theme: 'dark',
  lng: '',
  isLogin: false,
}

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.lng = action.payload
    },
    setTheme: (state, action) => {
      state.theme = action.payload
    },
  },

  extraReducers: (builder) => {
    builder
      // .addCase(loginAsync.pending, (state) => {
      //   state.loginLoading = true
      // })

      .addMatcher(
        (action) => action.type.endsWith('/setNetworkId')
        // (state, action) => {
        // 不支持网络更改
        // if (isAppSupportedNetworkId(action.payload)) {
        //   state.isSupportNetworkInfo = false
        // } else {
        //   state.isSupportNetworkInfo = true
        // }
        // }
      )
  },
})

export const { setLanguage } = globalSlice.actions

export default globalSlice.reducer
